'use strict';

window.ranger = window.ranger || {};

/**
 * Handles the single post UI.
 */
ranger.CreateHand = class {
  /**
   * Initializes the single post's UI.
   * @constructor
   */
  constructor() {
    // List of all times running on the page.
    this.timers = [];

    // Firebase SDK.
    this.database = firebase.database();
    this.auth = firebase.auth();

    $(document).ready(() => {


    });
  }

  initiateHand() {
      var value = 'Test';
  }

}

ranger.createHand = new ranger.CreateHand();
